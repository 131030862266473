import { createSlice } from "@reduxjs/toolkit";

const paymentPageSlice = createSlice({
  name: "paymentPage",
  initialState: {
    liveUserCount: 0,
    batchInfo: {},
    mentorInfo: {},
    discountStartTime: -1,
    discountEndTime: -1,
    paymentList: [],
    currentStep: 0,
    userDetails: {
      email: "",
      phone: "",
      name: "",
    },
    userReview: {
      rating: 0,
      remark: "",
      review: "",
      name: "",
    },
    showEnrollmentToast: false,
  },
  reducers: {
    setInitialValues(state, action) {
      const {
        liveUserCount,
        discountEndTime,
        discountStartTime,
        ...batchInfo
      } = action.payload.batch;
      state.liveUserCount = liveUserCount || 0;
      state.discountStartTime = discountStartTime;
      state.discountEndTime = discountEndTime;
      state.batchInfo = batchInfo;
      state.mentorInfo = action.payload?.mentor || {};
      state.paymentList = action.payload?.paymentList || [];
    },

    setCurrentStep(state, action) {
      state.currentStep = action.payload;
    },

    setSeatCnt(state, action) {
      state.batchInfo.seatCnt = action.payload.totalSeats;
    },

    setDiscountEndTime(state, action) {
      state.discountEndTime = action.payload;
    },

    setLiveUserCount(state, action) {
      state.liveUserCount = action.payload;
    },

    setPaymentList(state, action) {
      state.paymentList = action.payload;
      state.showEnrollmentToast = true;
    },

    setUserEmail(state, action) {
      state.userDetails.email = action.payload;
    },

    setUserPhone(state, action) {
      state.userDetails.phone = action.payload;
    },
    setUserReview(state, action) {
      state.userReview = {
        ...state.userReview,
        ...action.payload,
      };
    },
    setEnrollmentShowToast(state, action) {
      state.showEnrollmentToast = action.payload;
    },
    setUserEmailPhone(state, action) {
      state.userDetails.email = action.payload.email;
      state.userDetails.phone = action.payload.phone;
      state.userDetails.name = action.payload.name;
    },
  },
});

export const {
  setCurrentStep,
  setInitialValues,
  setDiscountEndTime,
  setPaymentList,
  setLiveUserCount,
  setUserEmail,
  setUserPhone,
  setUserReview,
  setEnrollmentShowToast,
  setUserEmailPhone,
  setSeatCnt,
} = paymentPageSlice.actions;
export const paymentPageReducer = paymentPageSlice.reducer;
