import Toast from "../PaymentPageComponents/Toast";
import { SocketContext } from "../../context/socket";
import { useState, useEffect, useContext } from "react";

import { useRef } from "react";

function ToastContainer() {
  const toastRef1 = useRef();
  const toastRef2 = useRef();
  const toastRef3 = useRef();

  console.log(toastRef1, toastRef2, toastRef3);
  const { socketBackend, socketAutomation } = useContext(SocketContext);

  function handleToastClick(type, data) {
    if (type === 1) return toastRef1.current.showToast(data);
    if (type === 2) return toastRef2.current.showToast(data);
    if (type === 3) return toastRef3.current.showToast(data);
  }
  useEffect(() => {
    socketBackend.on("showToast", (data) => {
      if (data.type === 1) {
        return handleToastClick(1, data.payment);
      }
      if (data.type === 2) {
        return handleToastClick(2, data.data);
      }
      if (data.type === 3) {
        return handleToastClick(3, data.data);
      }
    });
    socketAutomation.on("showToast", (data) => {
      if (data.type === 1) {
        return handleToastClick(1, data.payment);
      }
      if (data.type === 2) {
        return handleToastClick(2, data.data);
      }
      if (data.type === 3) {
        return handleToastClick(3, data.data);
      }
    });
  }, []);
  return (
    <>
      <Toast ref={toastRef1} timeout={5000} type={1} />
      <Toast ref={toastRef2} timeout={7000} type={2} />
      <Toast ref={toastRef3} timeout={7000} type={3} />
    </>
  );
}
export default ToastContainer;
