function EyeIcon({ fillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M15.9999 12C17.0608 12 18.0782 12.4214 18.8283 13.1716C19.5785 13.9217 19.9999 14.9391 19.9999 16C19.9999 17.0609 19.5785 18.0783 18.8283 18.8284C18.0782 19.5786 17.0608 20 15.9999 20C14.9391 20 13.9216 19.5786 13.1715 18.8284C12.4213 18.0783 11.9999 17.0609 11.9999 16C11.9999 14.9391 12.4213 13.9217 13.1715 13.1716C13.9216 12.4214 14.9391 12 15.9999 12ZM15.9999 6C22.6666 6 28.3599 10.1467 30.6666 16C28.3599 21.8533 22.6666 26 15.9999 26C9.33325 26 3.63992 21.8533 1.33325 16C3.63992 10.1467 9.33325 6 15.9999 6ZM4.23992 16C6.43992 20.48 10.9866 23.3333 15.9999 23.3333C21.0133 23.3333 25.5599 20.48 27.7599 16C25.5599 11.52 21.0133 8.66667 15.9999 8.66667C10.9866 8.66667 6.43992 11.52 4.23992 16Z"
        fill={fillColor}
      />
    </svg>
  );
}
export default EyeIcon;
