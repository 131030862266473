import {
  AppBar,
  Box,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import navIcon from "../../Pictures/trainityNav.svg";
function Navbar() {
  return (
    <Box sx={{ flexGrow: 1, boxShadow: "0 8px 16px rgb(0 0 0 / 6%)" }}>
      <AppBar
        position="static"
        sx={{
          backgroundColor: "#FFF",
          px: { md: "100px", xs: "5px" },
          py: { md: "15px" },
        }}
      >
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
          >
            <img src={navIcon} />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1 }}
          ></Typography>
          <Button
            sx={{
              padding: { md: "10px 22px", xs: "8px 15px" },
              backgroundColor: "#389F6C",
              borderRadius: "26px",
            }}
          >
            <Typography
              sx={{
                textTransform: "none",
                fontSize: { md: "16px", xs: "14px" },
                fontWeight: "700",
                color: "#FFF",
              }}
            >
              Log In
            </Typography>
          </Button>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
export default Navbar;
