function ClockIcon({ fillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
      <path
        d="M16.3335 26.6666C13.8581 26.6666 11.4842 25.6833 9.73383 23.9329C7.98349 22.1826 7.00016 19.8086 7.00016 17.3333C7.00016 14.8579 7.98349 12.4839 9.73383 10.7336C11.4842 8.98325 13.8581 7.99992 16.3335 7.99992C18.8089 7.99992 21.1828 8.98325 22.9332 10.7336C24.6835 12.4839 25.6668 14.8579 25.6668 17.3333C25.6668 19.8086 24.6835 22.1826 22.9332 23.9329C21.1828 25.6833 18.8089 26.6666 16.3335 26.6666ZM25.7068 9.85325L27.6002 7.95992C27.0002 7.27992 26.4002 6.66659 25.7202 6.07992L23.8268 7.99992C21.7602 6.31992 19.1602 5.33325 16.3335 5.33325C13.1509 5.33325 10.0987 6.59753 7.84821 8.84797C5.59778 11.0984 4.3335 14.1507 4.3335 17.3333C4.3335 20.5159 5.59778 23.5681 7.84821 25.8185C10.0987 28.069 13.1509 29.3333 16.3335 29.3333C23.0002 29.3333 28.3335 23.9599 28.3335 17.3333C28.3335 14.5066 27.3468 11.9066 25.7068 9.85325ZM15.0002 18.6666H17.6668V10.6666H15.0002M20.3335 1.33325H12.3335V3.99992H20.3335V1.33325Z"
        fill={fillColor}
      />
    </svg>
  );
}
export default ClockIcon;
