function ShoppingCartIcon({ fillColor }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
    >
      <path
        d="M14 0V5.33333H11.3333L16.6667 10.6667L22 5.33333H19.3333V0M2 2.66667V5.33333H4.66667L9.46667 15.4667L7.6 18.6667C7.46667 19.0667 7.33333 19.4667 7.33333 20C7.33333 21.4667 8.53333 22.6667 10 22.6667H26V20H10.5333C10.4 20 10.2667 19.8667 10.2667 19.7333V19.6L11.4667 17.3333H21.3333C22.2667 17.3333 23.2 16.8 23.6 16L28.8 6.66667L26.5333 5.33333L21.3333 14.6667H12L6.4 2.66667M10 24C8.53333 24 7.33333 25.2 7.33333 26.6667C7.33333 28.1333 8.53333 29.3333 10 29.3333C11.4667 29.3333 12.6667 28.1333 12.6667 26.6667C12.6667 25.2 11.4667 24 10 24ZM23.3333 24C21.8667 24 20.6667 25.2 20.6667 26.6667C20.6667 28.1333 21.8667 29.3333 23.3333 29.3333C24.8 29.3333 26 28.1333 26 26.6667C26 25.2 24.8 24 23.3333 24Z"
        fill={fillColor}
      />
    </svg>
  );
}
export default ShoppingCartIcon;
