import {
  Box,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  useRadioGroup,
} from "@mui/material";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import PropTypes from "prop-types";
const offerArr = [
  {
    label: "TrainityWebinar2999",
    subLabel: "Get 4500 off",
  },
];

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }
  if (checked) {
    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          width: "100%",
          padding: "16px",
          alignItems: "center",
          backgroundColor: "#E3F2EB",
        }}
      >
        <FormControlLabel
          className="CustomFormControlLabel"
          checked={checked}
          {...props}
          label={
            <Box>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "500", color: "#13113" }}
              >
                {props.label}
              </Typography>
              <Typography
                sx={{ fontSize: "12px", fontWeight: "600", color: "#777E8F" }}
              >
                {props.subLabel}
              </Typography>
            </Box>
          }
        />

        <Box>
          <Typography sx={{ fontWeight: "700" }}>{props.price}</Typography>
        </Box>
      </Box>
    );
  } else
    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          // border: "1px solid #DCDCDC",
          width: "100%",
          padding: "16px",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <FormControlLabel
          className="CustomFormControlLabel"
          checked={checked}
          {...props}
          //label={customLabel(props.label, props.price)}
          label={
            <Box>
              <Typography
                sx={{ fontSize: "14px", fontWeight: "600", color: "#13113" }}
              >
                {props.label}
              </Typography>
              <Typography
                sx={{ fontSize: "12px", fontWeight: "600", color: "#777E8F" }}
              >
                {props.subLabel}
              </Typography>
            </Box>
          }
        />

        <Box>
          <Typography sx={{ fontWeight: "700" }}>{props.price}</Typography>
        </Box>
      </Box>
    );
}

MyFormControlLabel.propTypes = {
  /**
   * The value of the component.
   */
  value: PropTypes.any,
};
function SelectOffer() {
  const [time, setTime] = useState(Date.now());
  const { discountEndTime } = useSelector((state) => {
    return state.paymentPage;
  });

  useEffect(() => {
    const updateTime = () => {
      setTime(Date.now());
    };
    setInterval(updateTime, 1000);

    return clearInterval(updateTime);
  }, []);

  return (
    <Box sx={{ backgroundColor: "#FFF", borderRadius: "8px" }}>
      <Box sx={{ padding: { md: "16px", xs: "12px" } }}>
        <Typography variant="h3">Select Offers</Typography>
      </Box>
      {time <= discountEndTime && (
        <Box sx={{ width: "100%" }}>
          <RadioGroup
            name="use-radio-group"
            defaultValue="first"
            sx={{ width: "100%" }}
          >
            {" "}
            {/* {offerArr.map((offer) => {
            return (
              <MyFormControlLabel
                value="first"
                label={offer.label}
                subLabel={offer.subLabel}
                control={<Radio />}
              />
            );
          })} */}
            <MyFormControlLabel
              value="first"
              label={offerArr[0].label}
              subLabel={offerArr[0].subLabel}
              control={<Radio />}
            />
            {/* <MyFormControlLabel
            value="second"
            label={offerArr[1].label}
            subLabel={offerArr[1].subLabel}
            control={<Radio />}
          /> */}
          </RadioGroup>
        </Box>
      )}
    </Box>
  );
}
export default SelectOffer;
