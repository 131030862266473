import { Container, Paper, Box, Button, IconButton } from "@mui/material";
import CourseInfo from "../components/PaymentPageComponents/CourseInfo";
import OrderSummary from "../components/PaymentPageComponents/OrderSummary";
import Pay from "../components/PaymentPageComponents/Pay";
import BatchComponent from "../components/PaymentPageComponents/BatchComponent";
import FAQ from "../components/PaymentPageComponents/FAQ";
import RatingReviews from "../components/PaymentPageComponents/RatingReviews";
import Collaboration from "../components/PaymentPageComponents/Collaboration";
import SelectOffer from "../components/SelectOffer";
import DiscountCounter from "../components/PaymentPageComponents/DiscountCounter";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";

import { useState, useEffect, useContext } from "react";
import { SocketContext } from "../context/socket";
import "react-toastify/dist/ReactToastify.css";

import LoadingComponent from "../components/LoadingComponent";
import { useFetchPaymentPageInfoHook } from "../hooks/paymentPageHooks";
import Navbar from "../components/PaymentPageComponents/Navbar";
import SwiperSlideComponent from "../components/PaymentPageComponents/SwiperSlideComponent";
import GallerySwiper from "../components/PaymentPageComponents/GallerySwiper";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

import WebinarInfoContainer from "../components/PaymentPageComponents/WebinarInfoContainer";
import Toast from "../components/PaymentPageComponents/Toast.js";
import ToastContainer from "../components/PaymentPageComponents/ToastContainer";
function PaymentPage2() {
  const socket = useContext(SocketContext);

  const [step, setStep] = useState(0);

  const { isError, isLoading, isFetching, error, data } =
    useFetchPaymentPageInfoHook();

  if (isFetching) return <LoadingComponent />;

  function Step0Container() {
    return (
      <>
        <Navbar />
        <Container
          component="section"
          sx={{
            background: "#FAFAFA",
            minHeight: "100vh",
            width: "100vw",
            maxWidth: "100vw !important",
            padding: {
              xs: "16px",
              md: "20px 80px",
            },
          }}
        >
          <Box
            component="section"
            sx={{
              display: "grid",
              alignItems: "center",
              justifyContent: "space-between",

              gridTemplateColumns: {
                xs: "1fr",
                lg: "1fr auto",
              },
              gap: "14px",

              maxWidth: "min(100%, 1536px)",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            {/* Course Info */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
                height: "100%",
              }}
            >
              {/* Webinar Info */}
              <WebinarInfoContainer />
              <Box
                sx={{ display: { xs: "grid", md: "none" }, maxWidth: "100px" }}
              >
                <SwiperSlideComponent />
              </Box>
              {/*End of Webinar Info */}
              <Paper
                sx={{
                  borderRadius: "8px",
                  background: "#FFF",
                  padding: {
                    xs: "12px",
                    md: "20px",
                  },
                  flexGrow: 1,
                }}
                elevation={0}
              >
                <Box
                  component="section"
                  sx={{
                    display: "grid",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                    gridTemplateColumns: {
                      xs: "1fr",
                      md: "auto 1fr",
                    },
                    gap: {
                      xs: "16px",
                      md: "30px",
                    },
                  }}
                >
                  {/* <Gallery /> */}
                  <GallerySwiper />
                  <CourseInfo />
                </Box>
              </Paper>
            </Box>
            {/* Order Related Components */}
            <Box
              component="section"
              sx={{
                display: { md: "flex", xs: "none" },
                flexDirection: "column",
                gap: "13px",
              }}
            >
              <DiscountCounter />
              <OrderSummary />
              <SelectOffer />
              <Pay variant={2} />
            </Box>
          </Box>
          <BatchComponent />
          <Collaboration />
          <RatingReviews />

          <FAQ />
          <Box
            sx={{
              width: "100%",
              display: { md: "none", xs: "grid" },
              position: "fixed",
              bottom: 10,
              zIndex: "1000",
            }}
          >
            <Button
              sx={{
                width: "92.5%",
                backgroundColor: "#389F6C",
                "&:hover": { backgroundColor: "#389F6C" },
                color: "#FFF",
                padding: "14px",
                borderRadius: "12px",
              }}
              onClick={() => {
                setStep(step + 1);
              }}
              endIcon={<ArrowForwardIcon />}
            >
              Next Step
            </Button>
          </Box>
        </Container>
      </>
    );
  }

  return (
    <>
      <ToastContainer />
      {step === 0 ? (
        <Step0Container />
      ) : (
        <>
          <Box sx={{ display: { xs: "none", md: "grid" } }}>
            <Step0Container />
          </Box>
          <Container
            component="section"
            sx={{
              display: { md: "none" },
              background: "#FAFAFA",
              minHeight: "100vh",
              width: "100vw",
              maxWidth: "100vw !important",
              padding: {
                xs: "16px",
                md: "80px",
              },
            }}
          >
            <Box
              component="section"
              sx={{
                display: "grid",
                alignItems: "center",
                justifyContent: "space-between",

                gridTemplateColumns: {
                  xs: "1fr",
                  lg: "1fr auto",
                },
                gap: "14px",

                maxWidth: "min(100%, 1536px)",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Box
                component="section"
                sx={{
                  display: { md: "none", xs: "flex" },
                  padding: "0 0 100px 0",
                  flexDirection: "column",
                  gap: "13px",
                }}
              >
                {" "}
                <Box sx={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
                  <IconButton
                    aria-label="back"
                    onClick={() => {
                      setStep(step - 1);
                    }}
                    size="small"
                  >
                    <ChevronLeftIcon />
                  </IconButton>
                  {/* <ExchangeOption /> */}
                </Box>
                <DiscountCounter />
                <OrderSummary />
                <SelectOffer />
                {/* <Coupons /> */}
                <Pay variant={2} />
              </Box>
            </Box>
          </Container>
        </>
      )}
    </>
  );
  if (step === 0)
    return (
      <>
        <ToastContainer />
        <Step0Container />
      </>
    );
  if (step === 1) {
    return (
      <>
        <ToastContainer />
        <Box sx={{ display: { xs: "none", md: "grid" } }}>
          <Step0Container />
        </Box>
        <Container
          component="section"
          sx={{
            display: { md: "none" },
            background: "#FAFAFA",
            minHeight: "100vh",
            width: "100vw",
            maxWidth: "100vw !important",
            padding: {
              xs: "16px",
              md: "80px",
            },
          }}
        >
          <Box
            component="section"
            sx={{
              display: "grid",
              alignItems: "center",
              justifyContent: "space-between",

              gridTemplateColumns: {
                xs: "1fr",
                lg: "1fr auto",
              },
              gap: "14px",

              maxWidth: "min(100%, 1536px)",
              marginLeft: "auto",
              marginRight: "auto",
            }}
          >
            <Box
              component="section"
              sx={{
                display: { md: "none", xs: "flex" },
                padding: "0 0 100px 0",
                flexDirection: "column",
                gap: "13px",
              }}
            >
              {" "}
              <Box sx={{ display: "grid", gridTemplateColumns: "auto 1fr" }}>
                <IconButton
                  aria-label="back"
                  onClick={() => {
                    setStep(step - 1);
                  }}
                  size="small"
                >
                  <ChevronLeftIcon />
                </IconButton>
                {/* <ExchangeOption /> */}
              </Box>
              <DiscountCounter />
              <OrderSummary />
              <SelectOffer />
              {/* <Coupons /> */}
              <Pay />
            </Box>
          </Box>
        </Container>
      </>
    );
  }
}

export default PaymentPage2;
