import React, { useEffect, useContext, useState } from "react";
import { SocketContext } from "../../context/socket";
import { Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { setLiveUserCount } from "../../store";
import "swiper/css";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import { Autoplay, Navigation } from "swiper/modules";
import { Box } from "@mui/material";
import WebinarInfo from "./WebinarInfo";
import { IconContext } from "react-icons";
import EyeIcon from "./Icons/EyeIcon";
import ShoppingCartIcon from "./Icons/ShoppingCartIcon";
import ClockIcon from "./Icons/ClockIcon";
function SwiperSlideComponent() {
  const { socketBackend, socketAutomation } = useContext(SocketContext);

  const dispatch = useDispatch();
  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    socketBackend.on("liveUserCountUpdated", (data) => {
      dispatch(setLiveUserCount(data));
    });
    const updateTime = () => {
      setTime(Date.now());
    };
    setInterval(updateTime, 1000);

    return clearInterval(updateTime);
  }, []);
  const { discountTimeLeft, liveUserCount, seatsFilled, discountEndTime } =
    useSelector((state) => {
      const { liveUserCount, discountEndTime, paymentList, batchInfo } =
        state.paymentPage;
      const discountTimeLeft = Math.max(
        0,
        Math.round((discountEndTime - Date.now()) / (1000 * 60))
      );
      return {
        discountTimeLeft,
        liveUserCount,
        seatsFilled: paymentList.length,
        discountEndTime,
      };
    });

  const swiperRef = React.useRef(null);
  const handleNext = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const handlePrev = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };
  return (
    <Box sx={{ maxWidth: "95vw", position: "relative" }}>
      <Swiper
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
        ref={swiperRef}
        navigation={{
          nextEl: ".custom-next-button",
          prevEl: ".custom-prev-button",
        }}
        modules={[Autoplay, Navigation]}
        slidesPerView={1.2}
        sx={{ display: "grid", placeContent: "center" }}
      >
        <SwiperSlide>
          <Box sx={{ marginRight: "10px" }}>
            <WebinarInfo
              icon={<EyeIcon fillColor="#48bf84" />}
              text={
                <Box>
                  <Typography variant="subtitle2" sx={{ fontSize: "16px" }}>
                    <span
                      style={{
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        color: "#131316",
                      }}
                    >
                      {liveUserCount}+
                    </span>{" "}
                    are viewing this page{" "}
                    <span
                      style={{
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        color: "#131316",
                      }}
                    >
                      LIVE
                    </span>
                  </Typography>
                </Box>
              }
            />
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box sx={{ marginRight: "10px" }}>
            <WebinarInfo
              icon={<ShoppingCartIcon fillColor="#1B7FF5" />}
              text={
                <Typography variant="subtitle2" sx={{ fontSize: "16px" }}>
                  <span
                    style={{
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      color: "#131316",
                    }}
                  >
                    {seatsFilled} People
                  </span>{" "}
                  have completed payment
                </Typography>
              }
            />
          </Box>
        </SwiperSlide>
        <SwiperSlide>
          <Box sx={{ marginRight: "10px" }}>
            <WebinarInfo
              icon={<ClockIcon fillColor="#982F93" />}
              text={
                <Typography variant="subtitle2" sx={{ fontSize: "16px" }}>
                  <span
                    style={{
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 700,
                      color: "#131316",
                    }}
                  >
                    {Math.max(
                      0,
                      Math.ceil((discountEndTime - time) / (1000 * 60))
                    )}{" "}
                    mins
                  </span>{" "}
                  left for the discounted price
                </Typography>
              }
            />
          </Box>
        </SwiperSlide>
      </Swiper>

      <Box
        sx={{
          display: "grid",
          placeContent: "center",
          position: "absolute",
          right: "0",
          top: "50%",
          transform: "translate(0,-50%)",
          zIndex: "1000",
        }}
      >
        <IconContext.Provider
          value={{ color: "white", className: "global-class-name" }}
        >
          <button
            style={{
              backgroundColor: "#389F6C",
              width: "fit-content",
              padding: "10px",
              borderRadius: 1000,
              display: "grid",
              placeContent: "center",
            }}
            className="custom-next-button"
          >
            <GrFormNext style={{ fill: "#FFF", color: "#fff" }} />
          </button>
        </IconContext.Provider>
      </Box>
    </Box>
  );
}

export default SwiperSlideComponent;
