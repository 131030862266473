import { Avatar, Box, Modal, Rating, Typography } from "@mui/material";
import VerifiedIcon from "./Icons/VerifiedIcon";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import CloseIcon from "@mui/icons-material/Close";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import prod1 from "../../Pictures/image-product-1.jpg";
import prod2 from "../../Pictures/image-product-2.jpg";
import prod3 from "../../Pictures/image-product-3.jpg";
import prod4 from "../../Pictures/image-product-4.jpg";

import thumb1 from "../../Pictures/image-product-1-thumbnail.jpg";
import thumb2 from "../../Pictures/image-product-2-thumbnail.jpg";
import thumb3 from "../../Pictures/image-product-3-thumbnail.jpg";
import thumb4 from "../../Pictures/image-product-4-thumbnail.jpg";
import ReviewModal from "./ReviewModal";
// import "./styles.css";

// import required modules

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: 1000,
  bgcolor: "background.paper",
  border: "2px solid #DCDCDC",
  boxShadow: 24,
  p: 4,
};
const slideSx = {
  display: "grid",
  placeContent: "center",
};
const prodImageSx = {
  width: "100%",
  borderRadius: "16px",
};
const thumbImageSx = {
  width: "10%",
  borderRadius: "6px",
};
const modalThumbImageSx = {
  width: "100%",
  borderRadius: "10px",
};
const modalProdImageSx = {
  width: "100%",
  borderRadius: "10px",
};
const phoneSliderImageSx = {
  width: "130px",
  borderRadius: "14px",
};
function ReviewCard({
  key,
  name,
  rating,
  remark,
  date,
  comment,
  verified,
  avatar,
  images,
}) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    if (images.length > 0) setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  return (
    <Box key={key}>
      <Box
        onClick={handleOpen}
        sx={{
          padding: { xs: "12px", md: "24px" },
          border: "1px solid #DCDCDC",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            alignItems: "center",
            columnGap: "12px",
          }}
        >
          <Avatar src={avatar} sx={{ width: "32px", height: "32px" }} />
          <Typography variant="subtitle2" sx={{ fontSize: "16px" }}>
            {name}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "auto",
              sm: "auto 1fr",
            },
            gap: "16px",
            marginTop: "12px",
          }}
        >
          <Box
            sx={{
              paddingRight: "16px",
              borderRight: "1px solid #DCDCDC",
              display: "grid",
              alignItems: "center",
            }}
          >
            <Rating value={rating} readOnly size="small" />
          </Box>
          <Box sx={{ display: "grid", alignItems: "center" }}>
            {" "}
            <Typography variant="h4">{remark}</Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            marginBottom: { xs: "16px", md: "24px" },
            mt: 1,
          }}
        >
          <Box sx={{ paddingRight: "16px", borderRight: "1px solid #DCDCDC" }}>
            <Typography
              variant="body2"
              sx={{
                display: { xs: "none", sm: "inline-block" },
                color: "#9C9CAB",
              }}
            >
              Review on
            </Typography>{" "}
            <Typography
              variant="body2"
              sx={{ display: "inline-block", color: "#9C9CAB" }}
            >
              {" " + date}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              alignItems: "center",
              paddingLeft: "16px",
              columnGap: "4px",
            }}
          >
            <VerifiedIcon />
            <Typography
              sx={{ fontSize: "12px", fontWeight: "700", color: "#1B7FF5" }}
            >
              {verified ? "Verified Purchase " : ""}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Typography>{comment}</Typography>
        </Box>
        <Box
          sx={{ display: { md: "flex", xs: "none" }, columnGap: "15px", mt: 2 }}
        >
          {images.map((image, index) => {
            return (
              <img
                key={index}
                src={image}
                style={{ width: "80px", borderRadius: "12px" }}
              />
            );
          })}
        </Box>
        <Box sx={{ display: { xs: "grid", sm: "none" } }}>
          {images.length > 0 && (
            <Swiper
              slidesPerView={2.5}
              spaceBetween={50}
              style={{ maxWidth: "80vw" }}
            >
              {images.map((image, key) => {
                return (
                  <SwiperSlide>
                    <img src={image} style={{ ...phoneSliderImageSx }} />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          )}
        </Box>
        <Box sx={{ display: { xs: "none", sm: "grid", md: "none" } }}>
          <Swiper
            slidesPerView={4}
            spaceBetween={50}
            style={{ maxWidth: "80vw" }}
          >
            {images.map((image, key) => {
              return (
                <SwiperSlide>
                  <img src={image} style={{ ...phoneSliderImageSx }} />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </Box>
        {/* phone image carousel */}
      </Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ display: { xs: "none", md: "grid" } }}
      >
        <Box sx={style}>
          <ReviewModal
            handleClose={handleClose}
            name={name}
            rating={rating}
            remark={remark}
            date={date}
            comment={comment}
          />
        </Box>
      </Modal>
    </Box>
  );
}
export default ReviewCard;
