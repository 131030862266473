import { Box, Typography, useTheme } from "@mui/material";
import RatingReviews from "../components/PaymentPageComponents/RatingReviews";
import Collaboration from "../components/PaymentPageComponents/Collaboration";
import FAQ from "../components/PaymentPageComponents/FAQ";
import BatchComponent from "../components/PaymentPageComponents/BatchComponent";

function HomePage() {
  const theme = useTheme();

  return (
    <Box
      sx={{
        px: { md: "80px", xs: "15px" },
        backgroundColor: " #F9F9FA",
        height: "100vh",
      }}
    >
      {" "}
      <BatchComponent />
      <Collaboration />
      <RatingReviews />
      <FAQ />
    </Box>
  );
}
export default HomePage;
