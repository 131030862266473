import { Avatar, AvatarGroup, Box, Typography } from "@mui/material";
import AvatarImage from "../../Pictures/Frame 1.svg";
import { useSelector } from "react-redux";
import moment from "moment";

const rowBoxSx = {
  padding: "16px",
  border: "1px solid #DCDCDC",
  gridTemplateColumns: "auto 1fr",
  display: "grid",
  columnGap: "16px",
  borderRadius: "8px",
  alignItems: "center",
};
const avatarSx = { width: "32px", height: "32px" };
function BatchDetails() {
  const { batchInfo, mentorInfo } = useSelector((state) => {
    return state.paymentPage;
  });

  return (
    <>
      <Box
        sx={{
          display: { md: "grid", xs: "none" },
          padding: "20px",
          minWidth: "376px",
          maxWidth: { lg: "416px" },
          backgroundColor: "#FFF",
          borderRadius: "8px",
          rowGap: "24px",
        }}
      >
        <Typography variant="h1">Batch Details</Typography>
        <Box sx={{ display: "grid", rowGap: "16px" }}>
          <Box sx={{ ...rowBoxSx }}>
            <Typography variant="subtitle2" sx={{ fontSize: "1rem" }}>
              Batch Name:
            </Typography>
            <Typography variant="h4" sx={{ color: "#389F6C" }}>
              {batchInfo.name}
            </Typography>
          </Box>
          <Box sx={{ ...rowBoxSx }}>
            <Typography variant="subtitle2" sx={{ fontSize: "1rem" }}>
              Program:
            </Typography>
            <Typography variant="h4">{batchInfo.course}</Typography>
          </Box>
          <Box sx={{ ...rowBoxSx }}>
            <Typography variant="subtitle2" sx={{ fontSize: "1rem" }}>
              Start Date:
            </Typography>
            <Typography variant="h4">{batchInfo.startDate}</Typography>
          </Box>
          <Box sx={{ ...rowBoxSx }}>
            <Typography variant="subtitle2" sx={{ fontSize: "1rem" }}>
              Size:
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                alignItems: "center",
              }}
            >
              <Typography variant="h4">{batchInfo.seatCnt} Students</Typography>
              <Box component="img" sx={{ ml: 2 }} src={AvatarImage} />
            </Box>
          </Box>
          <Box sx={{ ...rowBoxSx }}>
            <Typography variant="subtitle2" sx={{ fontSize: "1rem" }}>
              Mentor:
            </Typography>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                columnGap: "12px",
                alignItems: "center",
              }}
            >
              <Avatar src={mentorInfo.image} sx={{ ...avatarSx }} />
              <Typography variant="h4" sx={{ color: "#389F6C" }}>
                {mentorInfo.firstName + " " + mentorInfo.lastName}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: { xs: "grid", md: "none" },
          backgroundColor: "#FFF",
          border: "8px",
          rowGap: "16px",
          padding: "12px",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr auto",

            alignItems: "center",
          }}
        >
          <Box>
            <Typography variant="h2">{batchInfo.name}</Typography>
            <Typography variant="subtitle2">{batchInfo.startDate}</Typography>
          </Box>
          <Box>{/* <Avatar sx={{ height: "24px", width: "24px" }} /> */}</Box>
        </Box>
        <Box>
          <Typography variant="h3" sx={{ color: "#389F6C" }}>
            {batchInfo.course}
          </Typography>
        </Box>
        <Box
          sx={{ width: "100%", height: "0px", border: "1px solid #DCDCDC" }}
        ></Box>
        <Box>
          <Typography variant="h5">Mentor</Typography>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              columnGap: "12px",
              alignItems: "center",
              mt: 1,
            }}
          >
            <Avatar src={mentorInfo.image} />
            <Typography variant="h3" sx={{ color: "primary.main" }}>
              {mentorInfo.firstName + " " + mentorInfo.lastName}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
}
export default BatchDetails;
