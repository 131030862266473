import {
  Box,
  Button,
  LinearProgress,
  Modal,
  Pagination,
  Paper,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import ReviewCard from "./ReviewCard";
import { useState } from "react";
import { Reviews } from "@mui/icons-material";

import thumb1 from "../../Pictures/image-product-1-thumbnail.jpg";
import thumb2 from "../../Pictures/image-product-2-thumbnail.jpg";
import thumb3 from "../../Pictures/image-product-3-thumbnail.jpg";
import thumb4 from "../../Pictures/image-product-4-thumbnail.jpg";
import ReviewRegisterModal from "./ReviewRegisterModal";
const starRatings = [
  { type: 5, count: 1543, percentage: 89 },
  { type: 4, count: 141, percentage: 8 },
  { type: 3, count: 29, percentage: 1.6 },
  { type: 2, count: 11, percentage: 0.8 },
  { type: 1, count: 4, percentage: 0.2 },
];
const serviceRatings = [
  { name: "Project Experience", score: 4.9 },
  { name: "Learning Content Quality", score: 4.7 },
  { name: "Doubt Support", score: 4.1 },
  { name: "Live Classes", score: 4.8 },
  { name: "Placement Assistance", score: 4.4 },
];
const reviews = [
  {
    name: "Gopal Prasad",
    rating: 5,
    remark: "Overall good for this prices",
    date: "Sep, 28 2023",
    comment:
      "I was bit skeptical before enrolling due to a price of only ₹2999/- but the program turned out to be good for the price I paid. Overall recommended to everyone due to such a low price.",
    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Sushanta Panda",
    rating: 5,
    remark: "Got full refund after completing the program",
    date: "Sep, 25 2023",
    comment:
      "Received full refund of fee after submitting all 8 projects. Relieved to get my money back. Thanks team Trainity for this platform",
    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Aparna Vasudevan",
    rating: 5,
    remark: "Projects are the best",
    date: "Aug 30 2023",
    comment:
      "I had already learnt Digital-Marketing from YouTube and udemy and came here for projects. The projects provided on the platform is hands down of high quality and the best I could find on internet. Their learning content was good too, but I had already learn everything so I skipped the learning material and completed the program in just 25 days.",
    verified: true,
    avatar: "",
    images: [],
    images: [],
  },
  {
    name: "Rajat Nagar",
    rating: 5,
    remark: "Got Paid INTERNSHIP in BLR",
    date: "Aug 25 2023",
    comment:
      "I landed a paid internship in bengalore after completing the program. Super happy to get the chance to intern at a real company. I applied by myself on LinkedIn using the approach mentioned in program. Overall happy with the platform",
    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Mayur Pathak",
    rating: 4,
    remark: "Slow Doubt Support",
    date: "Jul 6 2023",
    comment:
      "Doubt support is very slow. Mentors will take 3-4 hrs before replying to your doubts. I am stuck.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Nandini Menon",
    rating: 4,
    remark: "Okay-Okay",
    date: "Jul 19 2023",
    comment: "price was low, program was okay-okay.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Chandni Gaglani",
    rating: 5,
    remark: "Good experiece",
    date: "Sep 15 2023",
    comment: "Had good experience in program.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Ranjan Kumar",
    rating: 5,
    remark: " Nice learning content",
    date: "Sep 17  2023",
    comment:
      "Have a great experiance with this course. At the begining, I totally don't know about Digital Marketing but after completion, I am so much confident in it. I experianced a perfect speed of learning not so fast & not so slow. its simply amazing.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Vanshika Shukla",
    rating: 5,
    remark: "Got a Raise",
    date: "Oct 7 20233",
    comment:
      "I already had 10 years of experience in IT domain. Took their program and followed everything as mentioned. Recetly got a raise of 20% in my same company. Not sure if it's because of trainity or my own efforts, but yes the program did help me upskill efficiently.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Jaya Charan",
    rating: 5,
    remark: "From Non-Tech Background",
    date: "Oct 12 2023",
    comment:
      "I did my B.Sc in Chemistry in 2020. Joined the program after so much pressure from their team. Didn't think I would get a job but I landed a 5LPA job as entry level business analyst in Capgemini. I am happy that I took that decission.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Pranjay Sharma",
    rating: 5,
    remark: "Not a SCAM",
    date: "Sep 18 2023",
    comment:
      "At first it looked like a scam to me. Read some reviews which called the program a scam but enrolled by risking my hard earned money. The program is LEGIT and i have got full refund as well. They should improve their marketing since it looks little shady. But 0 effectiv fee is true.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Sanad Saleem",
    rating: 4,
    remark: "Good Projects",
    date: "Sep 29 2023",
    comment:
      "Projects are quite good. Learning material is also nice. Doubt support is okay-okay. Everything other than this is nice.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Darvik Yadav",
    rating: 2,
    remark: "english required",
    date: "Jul 8 2023",
    comment:
      "program poora english mei h. merko kuch samajh nii aaya. waste of money.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Harsh Kumar",
    rating: 5,
    remark: "Best Experience with Trainity",
    date: "Sep 30 2023",
    comment:
      "One of the best experience at trainity. They teach everything very structurally and very accurate required in the data field. And at the end, after successful completion they provide certificates. And most importantly they do what they say about refund. I recommend. everyone to join trainity. It helped me a lot in gaining knowledge towards Digital Marketing.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Akashdeep Waliya",
    rating: 5,
    remark: "Good learning experience",
    date: "Oct 10 2023",
    comment:
      "Trust me, whoever says it's scam and all, they are wrong. I think this is a great course to learn Digital Marketing. I  gave my 100% commitment towards the course and learned a lot with less fees. My experience was awesome..🎈",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Rishabh Yadav",
    rating: 5,
    remark: "Best Curriculum for Digital Marketing",
    date: "Sep 29 2023",
    comment:
      "At Trainity, I opted for Digital Marketing track and I am super happy with the content. DA track consists of Excel-Beginner to advanced, SQL, Tableau, Powerbi, Python business problem solving with actual case-studies. The most exciting part of entire curriculum is frequent Live classes with Industry masters in Analytics field like Siddharth and Raghu sir. I cannot ask for a better Digital Marketing curriculum than Traininty at such a low cost.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Rajesh Goswami",
    rating: 5,
    remark: "Great Platform to learn Digital Marketing",
    date: "Sep 25 2023",
    comment:
      "Really cool learning platform. The Digital Marketing mentors are highly trained and packed with knowledge.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "​​Nitesh Singh",
    rating: 5,
    remark: "Highly recommended platform",
    date: "Oct 7 2023",
    comment:
      "It was a great experience with Trainity. They have very strong industry level learning platform. I highly recommend people who are targeting their dream jobs that learn from this platform. It will increase your value as employee in short amount of time.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Swati Mishra",
    rating: 5,
    remark: "Great for people who want to learn DA at low cost",
    date: "Sep 29 2023",
    comment:
      "I have enrolled in multiple programs and let me tell you this was the best program till date. I got all my cashback and got so much value (training) for completely free. I ended up earning as well during my internship. This is a great program for all students without any doubt.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Juhi Singh",
    rating: 5,
    remark: "Great Placement Assistance",
    date: "Sep 15 2023",
    comment:
      "I was a student of Trainity, so I can provide you the first hand experience. I am placed in a good company(Infosys) and I can vouch for the organisation. Whatever they say is true to its core. Their pay after program completion is 100% legit. Their staff is really friendly and approachable. I am go to them in case of any problem and even now they provide me with every assistance that I need.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Poulami Basu",
    rating: 5,
    remark: "Best Platform to learn Digital Marketing",
    date: "Sep 12 2023",
    comment:
      "At First, I would like to thanks to Trainity for their effort and for such a nice platform which helps students to reach their dream job. \n" +
      "Best part in Trainity : \n" +
      "1) Full refund after program completion \n" +
      "2) Great Curriculum \n" +
      "3) Best Mentors \n" +
      "3) Good Placement Guidance \n" +
      "4) Unique Digital Marketing training Platform \n" +
      "What else does a person need in his life for success? \n" +
      "I am happy to be a part of Trainity Community",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Rahul Kachhi",
    rating: 5,
    remark: "Gain Work Experience in DataAnalytics with Trainity",
    date: "Sep 11 2023",
    comment:
      "They not only teach us but also provide real-work experience. Once we complete the courses. The course was very comfortable with timings as I'm a working professional. I strongly recommend this to all the people out there.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Harsh Kumar",
    rating: 5,
    remark: "Great for people who want to learn DA at low cost",
    date: "Aug 24 2023",
    comment:
      "A must go for training program for anyone to enhance their career prospects by learning from top 1% practitioners in the industry. Great support team to address all your concerns to ensure you have a smooth learning.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Harsh Kumar",
    rating: 5,
    remark: "Great for people who want to learn DA at low cost",
    date: "Aug 24 2023",
    comment:
      "A must go for training program for anyone to enhance their career prospects by learning from top 1% practitioners in the industry. Great support team to address all your concerns to ensure you have a smooth learning.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Manisha Rana",
    rating: 5,
    remark: "Real-world project learning with Trainity",
    date: "Aug 16 2023",
    comment:
      "Don't go for online courses. Enroll in Trainity instead, the program is very better than any online course. You will not learn much from online course, here I learnt by working on real projects. Good experience.",

    verified: true,
    avatar: "",
    images: [],
  },
  {
    name: "Geeta Jaggi",
    rating: 5,
    remark: "Good place to learn and practice Digital Marketing",
    date: "Aug 8 2023",
    comment:
      "This was referred to me by seniors and I had referred it to many juniors. The projects are simply great. I have learnt more in last 8 weeks than 2 years of college.",

    verified: true,
    avatar: "",
    images: [],
  },
];
const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 450,
  bgcolor: "background.paper",
  // border: "2px solid #000",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
};
function RatingReviews({ props }) {
  const [viewIndex, setViewIndex] = useState(0);
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Paper
      id={"review-section"}
      elevation={0}
      sx={{
        padding: { xs: "12px", md: "20px" },
        backgroundColor: "#FFF",
        display: "grid",
        rowGap: "32px",
        marginBottom: "32px",
        maxWidth: "min(100%, 1536px)",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <Typography variant="h1">Ratings and Reviews</Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: { md: "1fr 2fr" },
          columnGap: "64px",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              alignItems: "center",
              padding: "10px 0",
              marginBottom: "16px",
            }}
          >
            <Box
              sx={{
                padding: "0 16px 0 0",
                display: "grid",
                columnGap: "8px",
                gridTemplateColumns: "1fr auto",
                height: "fit-content",
              }}
            >
              <Typography
                sx={{
                  fontSize: { md: "64px", xs: "40px" },
                  fontWeight: "800",
                  color: "primary.dark",
                }}
              >
                4.7
              </Typography>
              <Rating
                defaultValue={1}
                precision={0.5}
                max={1}
                size="large"
                readOnly
              />
            </Box>
            <Box>
              <Box
                sx={{ padding: "0 0 0 16px", borderLeft: "solid 1px #DCDCDC" }}
              >
                <Typography variant="body2">1728 Rating</Typography>
                {/* <Typography variant="body2">899 Reviews</Typography> */}
              </Box>
            </Box>
          </Box>
          <Box>
            {starRatings.map((rating) => {
              return (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto auto 1fr auto",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="body1">{rating.type}</Typography>
                  <Rating defaultValue={1} max={1} size="small" readOnly />
                  <LinearProgress
                    color="primary"
                    variant="determinate"
                    value={rating.percentage}
                    sx={{
                      marginLeft: "12px",
                      marginRight: "24px",
                    }}
                  />
                  <Typography variant="subtitle2">{rating.count}</Typography>
                </Box>
              );
            })}
          </Box>
          <Box
            sx={{
              width: "100%",
              border: "solid 1px #DCDCDC",
              my: { md: "32px", xs: "12px" },
            }}
          ></Box>
          <Box sx={{ display: "grid", rowGap: "8px" }}>
            <Typography variant="h3">By Services</Typography>
            {serviceRatings.map((rating) => {
              return (
                <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                  <Typography variant="h5">{rating.name}</Typography>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "auto 1fr",
                      alignItems: "center",
                      justifySelf: "flex-end",
                    }}
                  >
                    <Rating
                      value={rating.score}
                      precision={0.5}
                      readOnly
                      size="small"
                    />
                    <Typography variant="subtitle2" sx={{ marginLeft: "5px" }}>
                      {rating.score}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
          <Box
            sx={{
              width: "100%",
              border: "solid 1px #DCDCDC",
              my: { md: "32px", xs: "12px" },
            }}
          ></Box>
          <Box>
            <Typography variant="h3">Review this Course</Typography>
            <Typography variant="body2">
              Share your review with other friends
            </Typography>
            <Button
              onClick={handleOpen}
              sx={{
                width: "100%",
                padding: "6px 12px",
                marginTop: "16px",
                backgroundColor: "#E3F2EB",
                border: "1px solid #48BF84",
                borderRadius: "12px",
                marginBottom: { xs: "16px", md: 0 },
              }}
            >
              {" "}
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "700",
                  color: "#389F6C",
                  textTransform: "none",
                }}
              >
                Write a course review
              </Typography>
            </Button>
          </Box>
        </Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <ReviewRegisterModal />
          </Box>
        </Modal>
        <Box sx={{ display: "grid", rowGap: "20px", minHeight: "600px" }}>
          {reviews.map((review, index) => {
            let num = (viewIndex + 1) * 5;

            if (index <= num) {
              return (
                <ReviewCard
                  key={index}
                  name={review.name}
                  rating={review.rating}
                  remark={review.remark}
                  date={review.date}
                  comment={review.comment}
                  verified={review.verified}
                  avatar={review.avatar}
                  images={review.images}
                />
              );
            }
          })}
          <Button
            onClick={() => {
              setViewIndex(Math.max(reviews.length, viewIndex + 5));
            }}
          >
            {viewIndex < reviews.length ? "See More" : ""}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
}
export default RatingReviews;
