import {
  Container,
  Paper,
  Box,
  Button,
  Typography,
  Modal,
} from "@mui/material";

import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import WebinarInfo from "./WebinarInfo";

import EyeIcon from "./Icons/EyeIcon";
import ShoppingCartIcon from "./Icons/ShoppingCartIcon";
import ClockIcon from "./Icons/ClockIcon";
import { useState, useEffect } from "react";

function WebinarInfoContainer() {
  const { discountTimeLeft, liveUserCount, seatsFilled, discountEndTime } =
    useSelector((state) => {
      const { liveUserCount, discountEndTime, paymentList, batchInfo } =
        state.paymentPage;
      const discountTimeLeft = Math.max(
        0,
        Math.round((discountEndTime - Date.now()) / (1000 * 60))
      );
      return {
        discountTimeLeft,
        liveUserCount,
        seatsFilled: paymentList.length,
        discountEndTime,
      };
    });

  const [time, setTime] = useState(Date.now());

  useEffect(() => {
    const updateTime = () => {
      setTime(Date.now());
    };
    setInterval(updateTime, 1000);

    return clearInterval(updateTime);
  }, []);

  return (
    <Box
      sx={{
        display: { xs: "none", md: "grid" },
        gridTemplateColumns: "1fr 1fr 1fr",
        gap: "20px",
        maxWidth: "100%",
      }}
    >
      <WebinarInfo
        icon={<EyeIcon fillColor="#48bf84" />}
        text={
          <Box>
            <Typography variant="subtitle2" sx={{ fontSize: "16px" }}>
              <span
                style={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  color: "#131316",
                }}
              >
                {liveUserCount}+
              </span>{" "}
              are viewing this page{" "}
              <span
                style={{
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 700,
                  color: "#131316",
                }}
              >
                LIVE
              </span>
            </Typography>
          </Box>
        }
      />
      <WebinarInfo
        icon={<ShoppingCartIcon fillColor="#1B7FF5" />}
        text={
          <Typography variant="subtitle2" sx={{ fontSize: "16px" }}>
            <span
              style={{
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 700,
                color: "#131316",
              }}
            >
              {seatsFilled} People
            </span>{" "}
            have completed payment already
          </Typography>
        }
      />
      <WebinarInfo
        icon={<ClockIcon fillColor="#982F93" />}
        text={
          <Typography variant="subtitle2" sx={{ fontSize: "16px" }}>
            <span
              style={{
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 700,
                color: "#131316",
              }}
            >
              {Math.max(0, Math.ceil((discountEndTime - time) / (1000 * 60)))}{" "}
              mins
            </span>{" "}
            left for the discounted price
          </Typography>
        }
      />
    </Box>
  );
}
export default WebinarInfoContainer;
