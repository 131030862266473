import { Box } from "@mui/material";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

import prod1 from "../../Pictures/PHOTO-2023-12-20-20-44-28.jpg";
import prod2 from "../../Pictures/PHOTO-2023-12-20-20-44-28 2.jpg";
import prod3 from "../../Pictures/PHOTO-2023-12-20-20-44-29.jpg";
import prod4 from "../../Pictures/PHOTO-2023-12-21-10-13-55.jpg";

import "../PaymentPageComponents/gallery-styles.css";

function GallerySwiper() {
  const images = [
    {
      original: prod1,
      thumbnail: prod1,
    },
    {
      original: prod2,
      thumbnail: prod2,
    },
    {
      original: prod3,
      thumbnail: prod3,
    },
    {
      original: prod4,
      thumbnail: prod4,
    },
  ];
  const thumbnailStyles = {
    borderRadius: "12px",
  };

  return (
    <Box sx={{ maxWidth: "600px", marginLeft: "auto", marginRight: "auto" }}>
      <ReactImageGallery
        items={images}
        showPlayButton={false}
        showFullscreenButton={false}
        style={{
          "&.image-gallery-thumbnail": {
            "&.image-gallery-thumbnail-image": {
              border: "5px solid #DCDCDC",
            },
          },
          ".image-gallery-svg": {
            width: "30px",
          },
          " &.image-gallery-left-nav &.image-gallery-svg, &.image-gallery-right-nav &.image-gallery-svg":
            {
              height: "80px",
              width: "45px",
            },
        }}
        infinite={true}
      />
    </Box>
  );
}
export default GallerySwiper;
