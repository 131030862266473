import CircularProgress from "@mui/material/CircularProgress";
function LoadingComponent() {
  return (
    <div style={{ display: "grid", placeItems: "center", height: "100vh" }}>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <CircularProgress />
      </div>
    </div>
  );
}
export default LoadingComponent;
