import { Box, Button, TextField, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setUserEmail, setUserEmailPhone, setUserPhone } from "../../store";
import RazorpayPayButton from "./RazorpayPayButton";
import { useState } from "react";
import { validate } from "react-email-validator";

function PayModal({ onClose, localState, setLocalState }) {
  const [showError, setShowError] = useState({ email: false, phone: false });

  const setEmail = (val) => {
    setLocalState({ ...localState, ...{ email: val } });
  };
  const setPhone = (val) => {
    setLocalState({ ...localState, ...{ phone: val } });
  };
  const setName = (val) => {
    setLocalState({ ...localState, name: val });
  };

  function validateDetails() {
    if (validate(localState.email) && localState.phone.length === 10) {
      return true;
    }
    if (!validate(localState.email)) {
      setShowError({ ...showError, ...{ email: true } });
    } else {
      setShowError({ ...showError, ...{ email: false } });
    }

    if (!(localState.phone.length === 10)) {
      setShowError({ ...showError, ...{ phone: true } });
    } else {
      setShowError({ ...showError, ...{ phone: false } });
    }

    return false;
  }
  return (
    <Box sx={{ display: "grid", rowGap: "20px" }}>
      <Box>
        <Typography variant="body1">Full Name*</Typography>
        <Typography variant="subtitle1" mb={1}>
          Enter your Full Name
        </Typography>

        <TextField
          sx={{ width: "100%" }}
          type="text"
          value={localState.name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          placeholder="Fullname"
        ></TextField>
      </Box>
      <Box>
        <Typography variant="body1">Email Addres*</Typography>
        <Typography variant="subtitle1" mb={1}>
          Enter your correct E-mail address( Gmail preffered)
        </Typography>
        {showError.email && (
          <Typography variant="subtitle2" sx={{ color: "red" }}>
            *Please enter a valid email address
          </Typography>
        )}
        <TextField
          sx={{ width: "100%" }}
          type="email"
          value={localState.email}
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          placeholder="YourEmail@doamin.com"
        ></TextField>
      </Box>
      <Box>
        <Typography variant="body1">Phone Number*</Typography>
        <Typography variant="subtitle1" mb={1}>
          Enter your 10 digit Phone Number
        </Typography>
        {showError.phone && (
          <Typography variant="subtitle2" sx={{ color: "red" }}>
            Please enter a valid 10 digit phone number
          </Typography>
        )}
        <TextField
          sx={{ width: "100%" }}
          type="number"
          value={localState.phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          placeholder="98974569874"
        ></TextField>
      </Box>

      <Box>
        <RazorpayPayButton
          email={localState.email}
          phone={localState.phone}
          name={localState.name}
          validateDetails={validateDetails}
          // isValid={validateDetails()}
        />
      </Box>
    </Box>
  );
}
export default PayModal;
