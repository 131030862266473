import React from "react";

const VerifiedIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M15.3334 8.00019L13.7067 6.14686L13.9334 3.69352L11.5267 3.14686L10.2667 1.02686L8.00008 2.00019L5.73342 1.02686L4.47341 3.14686L2.06675 3.68686L2.29341 6.14019L0.666748 8.00019L2.29341 9.85352L2.06675 12.3135L4.47341 12.8602L5.73342 14.9802L8.00008 14.0002L10.2667 14.9735L11.5267 12.8535L13.9334 12.3069L13.7067 9.85352L15.3334 8.00019ZM6.66675 11.3335L4.00008 8.66686L4.94008 7.72686L6.66675 9.44686L11.0601 5.05352L12.0001 6.00019L6.66675 11.3335Z"
        fill="#1B7FF5"
      />
    </svg>
  );
};
export default VerifiedIcon;
