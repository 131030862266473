import { Box } from "@mui/material";
import BatchDetails from "./BatchDetails";
import BatchTable from "./BatchTable";

function BatchComponent() {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: { lg: "1fr auto" },
        rowGap: "12px",
        marginBottom: "32px",
        marginTop: {
          xs: "12px",
          md: "32px",
        },
        columnGap: "16px",
        maxWidth: "min(100%, 1536px)",
        marginLeft: "auto",
        marginRight: "auto",
      }}
    >
      <BatchTable />
      <BatchDetails />
    </Box>
  );
}
export default BatchComponent;
