import {
  Avatar,
  Box,
  Button,
  Divider,
  Rating,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setUserReview } from "../../store";
import { useState } from "react";

function ReviewRegisterModal() {
  const currentState = useSelector((state) => {
    return state.paymentPage;
  });
  const [review, setReview] = useState({
    rating: 0,
    review: "",
    remark: "",
    name: "",
  });

  const dispatch = useDispatch();
  //
  return (
    <Box sx={{ display: "grid", rowGap: "16px" }}>
      <Box>
        <Typography variant="h6" component="h2">
          Overall Rating
        </Typography>
        <Rating
          value={review.rating}
          onChange={(e) => {
            setReview({ ...review, ...{ rating: e.target.value } });
          }}
          size="large"
        />
      </Box>
      <Divider />
      <Box>
        <Typography variant="h6" component="h2">
          Add a Remark
        </Typography>
        <TextField
          value={review.remark}
          onChange={(e) => {
            setReview({ ...review, ...{ remark: e.target.value } });
          }}
          placeholder="Enter your remark here"
          sx={{ width: "100%" }}
        />
      </Box>
      <Divider />
      <Box>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Write a Course Review
        </Typography>
        <TextField
          value={review.review}
          onChange={(e) => {
            setReview({ ...review, ...{ review: e.target.value } });
          }}
          placeholder="Enter your comment here"
          multiline
          rows={4}
          sx={{ width: "100%" }}
        />
      </Box>
      <Divider />
      <Box>
        <Typography variant="h6">Choose your public name</Typography>
        <Typography variant="subtitle1">
          This is how you'll appear to others
        </Typography>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            alignItems: "center",
            columnGap: "16px",
            marginTop: "10px",
          }}
        >
          <Avatar sx={{ width: "50px", height: "50px" }} />
          <TextField
            value={review.name}
            onChange={(e) => {
              setReview({ ...review, ...{ name: e.target.value } });
            }}
            type="text"
            placeholder="your visible name"
          />
        </Box>
      </Box>
      <Divider />
      <Button
        onClick={() => {
          dispatch(setUserReview(review));
        }}
        sx={{
          width: "100%",
          backgroundColor: "#389F6C",
          marginTop: "16px",
          color: "#FFF",
          "&:hover": { backgroundColor: "#389F6C" },
        }}
      >
        Submit
      </Button>
    </Box>
  );
}
export default ReviewRegisterModal;
