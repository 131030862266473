import React from "react";
import {
  Container,
  Typography,
  Box,
  Rating,
  Divider,
  RadioGroup,
  Radio,
  useRadioGroup,
  styled,
  FormControlLabel,
} from "@mui/material";

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { FcInfo } from "react-icons/fc";
import PropTypes from "prop-types";
import CheckIcon from "@mui/icons-material/Check";

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }
  if (checked) {
    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          width: "100%",
          padding: "16px",
          alignItems: "center",
          backgroundColor: "#E3F2EB",
          pl: 4,
        }}
      >
        <FormControlLabel
          className="CustomFormControlLabel"
          checked={checked}
          {...props}
          label={
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#13113",
                  marginLeft: "8px",
                }}
              >
                {props.label}
              </Typography>
              <Typography
                sx={{ fontSize: "12px", fontWeight: "600", color: "#777E8F" }}
              >
                {props.subLabel}
              </Typography>
            </Box>
          }
        />

        <Box>
          <Typography sx={{ fontWeight: "700" }}>{props.price}</Typography>
        </Box>
      </Box>
    );
  } else
    return (
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr auto",
          width: "100%",
          padding: "16px",
          alignItems: "center",
          backgroundColor: "#fff",
        }}
      >
        <FormControlLabel
          className="CustomFormControlLabel"
          checked={checked}
          {...props}
          label={
            <Box>
              <Typography
                sx={{
                  fontSize: "14px",
                  fontWeight: "500",
                  color: "#13113",
                  marginLeft: "16px",
                }}
              >
                {props.label}
              </Typography>
              <Typography
                sx={{ fontSize: "12px", fontWeight: "600", color: "#777E8F" }}
              >
                {props.subLabel}
              </Typography>
            </Box>
          }
        />

        <Box>
          <Typography sx={{ fontWeight: "700" }}>{props.price}</Typography>
        </Box>
      </Box>
    );
}

MyFormControlLabel.propTypes = {
  /**
   * The value of the component.
   */
  value: PropTypes.any,
};

const CourseInfo = () => {
  const [time, setTime] = useState(Date.now());
  const { discountEndTime } = useSelector((state) => {
    return state.paymentPage;
  });

  useEffect(() => {
    const updateTime = () => {
      setTime(Date.now());
    };
    setInterval(updateTime, 1000);

    return clearInterval(updateTime);
  }, []);

  return (
    <Container
      sx={{ display: "flex", flexDirection: "column", gap: "18px" }}
      component="section"
      className="description"
    >
      {/* Course Title */}
      <Typography variant="h2" sx={{ mt: 4 }}>
        Digital Marketing Program
      </Typography>

      {/* Rating Info */}
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Typography variant="body1" sx={{ display: "inline-block" }}>
          4.7
        </Typography>
        <Rating readOnly value={4.7} precision={0.5} size="small" />
        <Typography
          variant="subtitle2"
          sx={{ display: "inline-block", color: "#C8C8D0" }}
        >
          |
        </Typography>
        <Typography
          variant="body2"
          sx={{
            display: "inline-block",
            color: "primary.dark",
            cursor: "pointer",
          }}
          onClick={() => {
            const element = document.getElementById("review-section");
            if (element) {
              element.scrollIntoView({ behavior: "smooth" });
            }
          }}
        >
          1748 Reviews
        </Typography>
      </Box>

      {/* Course Details */}
      <Box>
        {[
          "Intensive curriculum covering fundamentals to advanced concepts",
          "Real-World Project Experience",
          "Project Submission Refund Policy (100% Fee Refunded)",
        ].map((point, index) => {
          return (
            <Typography
              variant="subtitle2"
              key={index}
              sx={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                alignItems: "center",
                gap: "8px",
              }}
            >
              <CheckIcon sx={{ width: "20px" }} color="primary" />
              {point}
            </Typography>
          );
        })}
      </Box>

      {/* Pricing */}
      <Box sx={{ mt: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
          {time <= discountEndTime && (
            <Typography
              variant="h2"
              color="error.main"
              sx={{ textDecoration: "line-through" }}
            >
              ₹7499
            </Typography>
          )}

          <Typography variant="h2" sx={{ fontSize: "32px" }}>
            {time <= discountEndTime ? "₹2999" : "₹7499"}
          </Typography>
        </Box>
      </Box>

      <Divider sx={{ width: "100%", marginTop: "6px", marginBottom: "6px" }} />
      <Box
        sx={{ width: "100%", borderRadius: "8px", border: "1px solid #DCDCDC" }}
      >
        <RadioGroup
          name="use-radio-group"
          defaultValue="first"
          sx={{ width: "100%" }}
        >
          <MyFormControlLabel
            value="first"
            label="100% Refund Policy - Complete 8 Projects"
            // price="$499"
            control={<FcInfo />}
          />
          {/* <MyFormControlLabel
            value="second"
            label="Buy with Exchange"
            price="up to $59 off"
            control={<Radio />}
          /> */}
        </RadioGroup>
      </Box>
    </Container>
  );
};

export default CourseInfo;
