import { Avatar, Box, Rating, Typography } from "@mui/material";
import Gallery from "./Gallery";
import VerifiedIcon from "./Icons/VerifiedIcon";
import CloseIcon from "@mui/icons-material/Close";
function ReviewModal({ handleClose, name, rating, remark, date, comment }) {
  return (
    <Box>
      <Box sx={{ display: "grid", justifyItems: "flex-end " }}>
        <Box onClick={handleClose}>
          <CloseIcon />
        </Box>
      </Box>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: "16px",
        }}
      >
        <Box>
          {" "}
          <Gallery />
        </Box>
        <Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              alignItems: "center",
              columnGap: "12px",
            }}
          >
            <Avatar sx={{ width: "32px", height: "32px" }} />
            <Typography variant="subtitle2" sx={{ fontSize: "16px" }}>
              {name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              marginTop: "12px",
            }}
          >
            <Box
              sx={{
                paddingRight: "16px",
                borderRight: "1px solid #DCDCDC",
                display: "grid",
                alignItems: "center",
              }}
            >
              <Rating value={rating} readOnly size="small" />
            </Box>
            <Box
              sx={{
                paddingLeft: "16px",
                display: "grid",
                alignItems: "center",
              }}
            >
              {" "}
              <Typography variant="h4">{remark}</Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              marginBottom: { xs: "16px", md: "24px" },
              mt: 1,
            }}
          >
            <Box
              sx={{ paddingRight: "16px", borderRight: "1px solid #DCDCDC" }}
            >
              <Typography variant="body2" sx={{ color: "#9C9CAB" }}>
                Review on {date}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "auto 1fr",
                alignItems: "center",
                paddingLeft: "16px",
                columnGap: "4px",
              }}
            >
              <VerifiedIcon />
              <Typography
                sx={{ fontSize: "12px", fontWeight: "700", color: "#1B7FF5" }}
              >
                {" "}
                Verified
              </Typography>
            </Box>
          </Box>
          <Box>
            <Typography>{comment}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
export default ReviewModal;
