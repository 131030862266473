import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";

import {
  paymentPageReducer,
  setCurrentStep,
  setInitialValues,
  setDiscountEndTime,
  setPaymentList,
  setLiveUserCount,
  setUserEmail,
  setUserPhone,
  setUserReview,
  setUserEmailPhone,
  setEnrollmentShowToast,
  setSeatCnt,
} from "./slice/paymentPageSlice";

const store = configureStore({
  reducer: {
    paymentPage: paymentPageReducer,
  },
});

setupListeners(store.dispatch);

export {
  store,
  setCurrentStep,
  setInitialValues,
  setDiscountEndTime,
  setPaymentList,
  setLiveUserCount,
  setUserEmail,
  setUserPhone,
  setUserReview,
  setEnrollmentShowToast,
  setUserEmailPhone,
  setSeatCnt,
};
