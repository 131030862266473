import { Paper, Box, Avatar, Typography } from "@mui/material";
import EyeIcon from "./Icons/EyeIcon";

function WebinarInfo({ icon, text }) {
  return (
    <Paper
      elevation={0}
      sx={{ borderRadius: "8px", padding: "16px", background: "#fff" }}
    >
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          alignItems: "center",
          gap: "20px",
        }}
      >
        <Avatar sx={{ bgcolor: "#F9F9FA", width: "60px", height: "60px" }}>
          {icon}
        </Avatar>

        {text}
      </Box>
    </Paper>
  );
}
export default WebinarInfo;
