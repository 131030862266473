import { Box, LinearProgress, Typography } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SocketContext } from "../../context/socket";

import moment from "moment";
import { setDiscountEndTime } from "../../store";

const defaultState = { m: "00", s: "00" };

const addPadding = (value, len) => {
  let strVal = value.toString();
  if (strVal.length < len) {
    const paddingLen = len - strVal.length;
    for (let i = 0; i < paddingLen; i++) {
      strVal = "0" + strVal;
    }
    return strVal;
  }
  return strVal;
};

function DiscountCounter() {
  const { socketBackend, socketAutomation } = useContext(SocketContext);

  const dispatch = useDispatch();

  const { discountEndTime, liveUserCount, seatCnt, seatsFilled } = useSelector(
    (state) => {
      const {
        liveUserCount,
        discountEndTime,
        paymentList,
        batchInfo,
        discountStartTime,
      } = state.paymentPage;
      const discountTimeLeft = Math.max(
        0,
        Math.round((discountEndTime - Date.now()) / (1000 * 60))
      );
      return {
        discountTimeLeft,
        liveUserCount,
        seatsFilled: paymentList.length,
        seatCnt: batchInfo.seatCnt,
        discountEndTime,
      };
    }
  );

  const [remainingTime, setRemainingTime] = useState(defaultState);
  const updateRemainingTime = (currentTime) => {
    const timeStamp = discountEndTime;

    const diff = Math.round((timeStamp - Date.now()) / 1000);
    if (diff < 0) {
      return setRemainingTime({
        m: "00",
        s: "00",
      });
    }

    setRemainingTime({
      m: addPadding(Math.round(diff / 60), 2),
      s: addPadding(diff % 60, 2),
    });
  };
  useEffect(() => {
    const updateTime = () => {
      updateRemainingTime(Date.now());
    };
    setInterval(updateTime, 1000);

    socketBackend.on("deadlineExtended", (data) => {
      dispatch(setDiscountEndTime(data.newTime));
    });

    return clearInterval(updateTime);
  }, []);

  return (
    <Box
      sx={{
        padding: "20px",
        backgroundColor: "#FFF",
        borderRadius: "12px",
        display: "grid",
        rowGap: "16px",
      }}
    >
      <Typography variant="h3">61% Discount Validity</Typography>
      <Typography variant="h4">Expiring In:</Typography>
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "auto 1fr",
          columnGap: "16px",
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            alignItems: "center",
            columnGap: "8px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#EE2737",
              padding: "4px",
              color: "#FFF",
              display: "grid",
              placeItems: "center",
              borderRadius: "4px",
            }}
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: "700", color: "#FFF" }}
            >
              {remainingTime.m}
            </Typography>
          </Box>
          <Box>Minutes</Box>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "auto 1fr",
            alignItems: "center",
            columnGap: "8px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "#EE2737",
              padding: "4px",
              color: "#FFF",
              display: "grid",
              placeItems: "center",
              borderRadius: "4px",
            }}
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: "700", color: "#FFF" }}
            >
              {remainingTime.s}
            </Typography>
          </Box>
          <Box>Seconds</Box>
        </Box>
      </Box>
      <Box>
        <LinearProgress
          variant="determinate"
          value={(seatsFilled / seatCnt) * 100}
        />
        <Typography
          sx={{ fontSize: "12px", color: "#EE2737", fontWeight: "500" }}
        >
          {seatsFilled}/{seatCnt} Seats are already filled for the program
        </Typography>
      </Box>
    </Box>
  );
}
export default DiscountCounter;
