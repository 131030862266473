import { Paper, Typography, Box, Chip, Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { SocketContext } from "../../context/socket";

function OrderDetail({ title, value, pillContent }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Typography variant="body2">
        {title}{" "}
        {pillContent?.length > 0 && (
          <Chip
            label={pillContent}
            size="small"
            sx={{
              backgroundColor: "secondary.dark",
              color: "#fff",
              fontSize: "10px",
            }}
          />
        )}
      </Typography>
      <Typography variant="body2" color="primary">
        {value}
      </Typography>
    </Box>
  );
}

function OrderSummary() {
  const [time, setTime] = useState(Date.now());
  const dispatch = useDispatch();

  const { discountEndTime } = useSelector((state) => {
    return state.paymentPage;
  });

  useEffect(() => {
    const updateTime = () => {
      setTime(Date.now());
    };
    setInterval(updateTime, 1000);

    return clearInterval(updateTime);
  }, []);
  return (
    <Paper
      sx={{
        borderRadius: "8px",
        background: "#FFF",
        padding: { md: "20px", xs: "12px" },
        minWidth: "300px",
      }}
      elevation={0}
    >
      <Typography variant="h3">Order Summary</Typography>
      <Box sx={{ mt: 2, display: "flex", flexDirection: "column", gap: "8px" }}>
        <OrderDetail title="Original Price" value="₹7499" />

        {time <= discountEndTime && (
          <OrderDetail title="Discount" value="-4500" pillContent="61%" />
        )}
        <OrderDetail
          title="GST Tax"
          value={time <= discountEndTime ? "₹438" : "1349"}
        />
      </Box>
      <Divider
        sx={{
          display: { md: "grid", xs: "none" },
          width: "100%",
          mt: "1.5rem",
          mb: "1.5rem",
        }}
      />
      <Box
        sx={{
          display: { md: "flex", xs: "none" },
          justifyContent: "space-between",
          padding: "8px",
          borderRadius: "4px",
          alignItems: "center",
          background: "#F4F4F6",
        }}
      >
        <Typography variant="body2" color="primary">
          Total Payment
        </Typography>
        <Typography variant="body2" color="primary">
          {time <= discountEndTime ? "₹3438" : "₹8848"}
        </Typography>
      </Box>
    </Paper>
  );
}
export default OrderSummary;
