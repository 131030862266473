import { Avatar, Box, Button, Typography } from "@mui/material";
import { useContext, useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import moment from "moment";
import {
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

import { SocketContext } from "../../context/socket";

import { useDispatch, useSelector } from "react-redux";
import { setPaymentList, setSeatCnt } from "../../store";

import { FcInfo } from "react-icons/fc";

import "react-toastify/dist/ReactToastify.css";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F4F4F6",
    color: "#131313",
    fontSize: "14px",
    fontWeight: "500",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: "#F9F9FA",
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(name, avatar, email, registeredOn, benchNumber) {
  return { name, avatar, email, registeredOn, benchNumber };
}

function CustomTable({ rows }) {
  if (rows.length === 0) {
    return (
      <Box sx={{ display: "grid", placeContent: "center" }}>
        <Typography sx={{ fontSize: "20px", fontWeight: "600" }}>
          No Enrollements Yet
        </Typography>
      </Box>
    );
  }
  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        maxHeight: { xs: "358px", md: "400px" },
      }}
    >
      <Table
        stickyHeader
        sx={{ minWidth: { md: 700 } }}
        aria-label="customized table"
      >
        <TableHead>
          <TableRow>
            <StyledTableCell>
              <Typography variant="body2">Student Name</Typography>
            </StyledTableCell>
            <StyledTableCell
              align="center"
              sx={{
                display: {
                  xs: "none",
                  md: "table-cell",
                },
              }}
            >
              <Typography variant="body2">Enrolled On</Typography>
            </StyledTableCell>
            <StyledTableCell align="center">
              <Typography variant="body2">Seat Number</Typography>
            </StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell component="th" scope="row">
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto 1fr",
                    columnGap: "13px",
                  }}
                >
                  <Box>
                    <Avatar sx={{ height: "48px", width: "48px" }}>
                      {row.name[0]}
                    </Avatar>
                  </Box>
                  <Box>
                    <Typography variant="h4">{row.name}</Typography>
                    <Typography variant="subtitle2">{row.email}</Typography>
                  </Box>
                </Box>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                sx={{
                  display: {
                    xs: "none",
                    md: "table-cell",
                  },
                }}
              >
                <Typography variant="body1" sx={{ fontWeight: "700" }}>
                  {row.registeredOn}
                </Typography>
              </StyledTableCell>
              <StyledTableCell align="center">
                <Typography variant="body1" sx={{ fontWeight: "700" }}>
                  {row.benchNumber}
                </Typography>
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function BatchTable() {
  const [viewIndex, setViewIndex] = useState(5);
  const { socketBackend, socketAutomation } = useContext(SocketContext);
  const dispatch = useDispatch();

  useEffect(() => {
    socketBackend.on("updatePayments", (data) => {
      dispatch(setPaymentList(data));
    });

    socketBackend.on("seatsAdded", (data) => {
      dispatch(setSeatCnt(data));
    });

    socketAutomation.on("updatePayments", (data) => {
      dispatch(setPaymentList(data));
    });

    socketAutomation.on("testEvent", (data) => {
      console.log(data);
    });
  }, []);

  const { paymentListRows, seatCnt, seatLeft } = useSelector((state) => {
    let { paymentList, batchInfo } = state.paymentPage;
    const paymentCount = paymentList.length;

    paymentList = paymentList.map((payment, index) => {
      const name = payment.firstName + " " + payment.lastName;
      const seatNumber = paymentCount - index;
      const email = payment.email;
      const registeredOn = moment(payment.enrolledAt).format(
        "h:mm A MMM DD, YYYY"
      );

      return createData(name, "", email, registeredOn, seatNumber);
    });

    return {
      paymentListRows: paymentList,
      seatCnt: batchInfo.seatCnt,
      seatLeft: Math.max(0, batchInfo.seatCnt - paymentList.length),
    };
  });

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#FFF",
          borderRadius: "8px",
          padding: { md: "20px", xs: "12px" },
          display: "grid",
          gridTemplateRows: "auto 1fr",
          rowGap: "24px",
        }}
      >
        <Box
          sx={{
            display: { md: "grid", xs: "none" },
            gridTemplateColumns: { md: "repeat(6,auto) 1fr" },
            height: "fit-content",
            columnGap: "16px",
            alignItems: "center",
          }}
        >
          <Typography variant="h1">Live Enrollments</Typography>
          <Typography
            sx={{
              fontSize: "16px",
              fontWeight: "500",
              color: "#1B7FF5",
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              alignItems: "center",
            }}
          >
            <FcInfo /> Available only {seatCnt} Students
          </Typography>
          <Box
            sx={{ width: "0px", height: "100%", border: "1px solid #DCDCDC" }}
          ></Box>
          <Typography
            sx={{ fontSize: "16px", fontWeight: "500", color: "#389F6C" }}
          >
            Quotas Available: {paymentListRows.length}/{seatCnt}
          </Typography>
          <Box
            sx={{ width: "0px", height: "100%", border: "1px solid #DCDCDC" }}
          ></Box>
          <Typography
            sx={{ fontSize: "16px", fontWeight: "500", color: "#EE2737" }}
          >
            Seat Left: {seatLeft}
          </Typography>
          <Box></Box>
        </Box>
        <Box sx={{ display: { xs: "grid", md: "none" } }}>
          <Box sx={{ display: "grid", gridTemplateRows: "1fr auto 1fr" }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr auto",
                padding: "4px",
              }}
            >
              <Box sx={{ color: "#389F6C", justifySelf: "left" }}>
                <Typography sx={{ fontSize: "1.25rem", fontWeight: "700" }}>
                  Live Enrollments
                </Typography>
              </Box>
              <Box
                sx={{
                  justifySelf: "right",
                  display: "grid",
                  gridTemplateColumns: "auto 1fr",
                  alignItems: "center",
                }}
              >
                <FcInfo />
                <Typography
                  variant="subtitle2"
                  sx={{
                    fontSize: "14px",
                    color: "#1B7FF5",
                    width: "fit-content",
                  }}
                >
                  Available only {seatCnt} students
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{ height: 0, width: "100%", border: "1px solid #DCDCDC" }}
            ></Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                padding: "4px",
              }}
            >
              <Box sx={{ justifySelf: "left" }}>
                <Typography
                  variant="subtitle2"
                  sx={{ fontSize: "14px", color: "#389F6C" }}
                >
                  Quotas available: {paymentListRows.length}/{seatCnt}
                </Typography>
              </Box>
              <Box sx={{ justifySelf: "right" }}>
                <Typography
                  variant="subtitle2"
                  sx={{ color: "#EE2737", fontSize: "14px" }}
                >
                  Seat Left: {seatLeft}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "grid" },
            gridTemplateRows: "1fr auto",
          }}
        >
          <CustomTable rows={paymentListRows} />
        </Box>
        <Box sx={{ display: { xs: "grid", md: "none" }, rowGap: "12px" }}>
          {paymentListRows.map((row, index) => {
            if (index <= viewIndex)
              return (
                <Box
                  sx={{
                    display: "grid",
                    gridTemplateColumns: "auto 1fr",
                    padding: "20px",
                    columnGap: "12px",
                    alignItems: "center",
                    border: "1px solid #DCDCDC",
                    borderRadius: "12px",
                  }}
                >
                  <Avatar>{row.name[0]}</Avatar>
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "1fr ",
                      rowGap: "6px",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "grid",
                          gridTemplateColumns: "1fr auto",
                        }}
                      >
                        <Typography variant="h4">
                          {row.name.length < 25
                            ? row.name
                            : row.name.substring(0, 25) + "..."}
                        </Typography>
                        <Typography variant="h4">
                          {paymentListRows.length - index}
                        </Typography>
                      </Box>
                      <Typography variant="subtitle1">{row.email}</Typography>
                    </Box>
                    <Box>
                      <Typography variant="subtitle2">
                        {row.registeredOn}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              );
          })}
          <Button
            onClick={() => {
              setViewIndex(Math.max(paymentListRows.length, viewIndex + 5));
            }}
          >
            {viewIndex < paymentListRows.length ? "See More" : ""}
          </Button>
        </Box>
      </Box>
    </>
  );
}
export default BatchTable;
