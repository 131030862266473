import { useQuery, useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { setInitialValues } from "../store";
import axios from "axios";

/**
 * Fetch Payment Page Info
 */

const fetchPaymentPageInfo = async (dispatch) => {
  const response = await fetch(
    `https://payments.trainity.live/api/batch/current`,
    {
      method: "GET",
    }
  );
  const data = await response.json();

  if (response.status === 200) {
    dispatch(setInitialValues(data));
  }
  return response;
};

export const useFetchPaymentPageInfoHook = () => {
  const dispatch = useDispatch();
  return useQuery(
    "get-payment-page-info",
    () => fetchPaymentPageInfo(dispatch),
    {
      refetchOnWindowFocus: false,
    }
  );
};

/**
 * Create Order Id (Mutation)
 */

const createOrder = async (user) => {
  const response = await fetch(
    `https://payments.trainity.live/api/payment/create/order`,
    {
      method: "POST",
      body: JSON.stringify(user),
      headers: { "Content-Type": "application/json" },
    }
  );
  const data = await response.json();

  return data;
};

export const useCreateOrder = () => {
  return useMutation(createOrder);
};

/**
 * Mark Payment (Mutation)
 */

const markPayment = async (user) => {
  const response = await fetch(
    `https://payments.trainity.live/api/payment/mark`,
    {
      method: "POST",
      body: JSON.stringify(user),
      headers: { "Content-Type": "application/json" },
    }
  );
  const data = await response.json();

  return data;
};

export const useMarkPayment = () => {
  return useMutation(markPayment);
};
